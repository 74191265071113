@tailwind base;
@tailwind components;
@tailwind utilities;



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.carousel {
  display: flex;
  text-align: center;
  justify-content: left;
 flex-wrap: nowrap; 
}


.carousel-container {
  padding: 2vh;
  margin-bottom: 2vh;
}

.carousel-container.selected {
    transform: scale(1.1);
}


#pumpkinbanner {
  background: orange;
  background-image: url("./images/sunset.png"); /* fallback */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image:     linear-gradient(to left, rgba(255, 124, 1, 0.5), #1b1b1bbd),
  url('./images/sunset.png');
}



#pumpkinbanner:hover 
{ background-color: #ed4811; background-image: linear-gradient( to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .2) 100% ); transition: background-color .3s ease-in-out; }


#firered {
  background-color: #1b1b1b;
  border: solid 3px orangered;
}



  

  .headline-box {
    display: flex;
    justify-content: center;
    margin-top: 10vh;
  }
 
  




.berry-div {
 min-width: 150px;
 border-radius: 20px;
 
}


#email {
  height: 38px; padding: 0 16px; font-size: 14px; border: 1px solid #bec3c8; border-radius: 3px; margin-right: 8px; font-family: 'Helvetica Neue', Helvetica, sans-serif;" /><input type="hidden" name="embed" value="true" /><button type="submit" style="cursor: pointer; background-color: #456d9c; color: white; height: 40px; border: 0; border-radius: 3px; font-size: 14px; padding: 0 16px; font-family: 'Helvetica Neue', Helvetica, sans-serif; font-weight: 500;
}

.modal-details {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  top: 0;
  left: 0;
  width: 80vw;
  height: auto;
  z-Index: 100;
  justify-Content: center;
  align-Items: center;
  background-color: white;
  padding-top: 3vh;
  padding-bottom: 3vh;
  padding-left: 1vh;
  padding-right: 1vh;
}

.berry-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  opacity: 0;
  transform: translateY(20px);
  margin: 10vh;
}



.berry-details.show {
  opacity: 1;
  transform: translateY(0);
}


.blur-background {
  pointer-events: none;
  
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  opacity:0.8; 
  z-index: 1000; 
  
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
}

/* .modal-open {
  overflow: hidden;
} */

.berry-details h2 {
  margin-bottom: 10px;
}

.berry-details p {
  color: #555;
}

.title-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.title-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 3vh;
}


.button-box {
  display: flex;
  gap: 10px;
  justify-content: center;

}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}



.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


button {
  background-color: #CB1050;
}


#bg {
  height: 800px;
  background-image: url("./images/photo2.jpg");
  

}

@font-face {
  font-family: 'friz';
  font-style: normal;
  font-weight: normal;
  src: local('Friz Quadrata Regular OS'), url('friz-quadrata-regular-os-5870333951e7c.woff') format('woff');
  }
  

  @font-face {
  font-family: 'friz-i';
  font-style: normal;
  font-weight: normal;
  src: local('Friz Quadrata Bold Italic OS'), url('friz-quadrata-bold-italic-os-5870341205e0f.woff') format('woff');
  }


  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');


  #friz {
    font-family: 'friz';

  }

  #Poppins {
    font-family: 'Poppins'
  }

  a, h2 {
    font-family: 'Poppins'
  }

  p {
    font-family: 'Raleway';
    
  }

  #raleway {
    font-family: 'Raleway';
    
  }



#bg1 {
  
  background-image: linear-gradient(45deg, rgb(203,16,80, 0.9), rgba(255, 189, 20, 0.7)), url("./images/blueberry.PNG");
}

#berrycarebg {
  
  background-image: linear-gradient(45deg, rgb(203,16,80, 0.9), rgba(255, 189, 20, 0.7)), url("./images/berrycare1.jpg");
  background-repeat: no-repeat;
  width: 100vw;

  background-size: 100% auto;


}

#storybg {
  
  background-image: linear-gradient(45deg, rgb(203,16,80, 0.9), rgba(255, 189, 20, 0.7)), url("./images/storybg.png");
  background-repeat: no-repeat;
  width: 100vw;

  background-size: 100% auto;


}

#contactbg {
  
  background-image: linear-gradient(45deg, rgb(203,16,80, 0.9), rgba(255, 189, 20, 0.7)), url("./images/photo5.jpg");
  background-repeat: no-repeat;
  width: 100vw;
  background-position: center;


  background-size: 100% auto;


}

#visitbg1 {
  
  background-position: top;
  background-image: linear-gradient(30deg, rgb(203,16,80, 0.8), rgba(255, 189, 20, 0.8)), url("./images/visitfarm.jpg");
}

#marketbg {
  background-image: linear-gradient(45deg, rgb(203,16,80, 0.9), rgba(255, 189, 20, 0.7)), url("./images/marketbg.jpg");
  background-repeat: no-repeat;
  width: 100vw;
  background-position: center;
  background-size: 100% auto;
}


/*255, 189, 20, 0.6 */

@media screen and (max-width: 600px) {
  #bg {
    height: 40em;
    background-image: linear-gradient(45deg, rgba(255, 189, 20, 0.2), rgba(255, 189, 20, 0.2)), url("./images/SidhuFarms7.jpg"), ;
    background-position: top;

    
  
  }
  #bg1 {
    height: auto;
    background-image: linear-gradient(45deg, rgb(203,16,80, 0.9), rgba(255, 189, 20, 0.7)), url("./images/blueberry.PNG");
  }

  #berrycarebg {
    background-image: linear-gradient(45deg, rgb(203,16,80, 0.9), rgba(255, 189, 20, 0.7)), url("./images/berrycare1.jpg");
    background-repeat: no-repeat;
  
    background-size: 100vh auto;
  
  
  }

  #storybg {
    background-image: linear-gradient(45deg, rgb(203,16,80, 0.9), rgba(255, 189, 20, 0.7)), url("./images/storybg.png");
    background-repeat: no-repeat;
  
    background-size: 100vh auto;
  
  
  }

  #contactbg {
    background-image: linear-gradient(45deg, rgb(203,16,80, 0.9), rgba(255, 189, 20, 0.7)), url("./images/photo5.jpg");
    background-repeat: no-repeat;
  
    background-size: 100vh auto;
  
  
  }

  #marketbg {
    background-image: linear-gradient(45deg, rgb(203,16,80, 0.9), rgba(255, 189, 20, 0.7)), url("./images/marketbg.jpg");
    background-repeat: no-repeat;
  
    background-size: 100vh auto;
  
  }
  #berryimg {
    rotate: 45deg;
  }
  .carousel {
    flex-direction: column;
}

.carousel-container {
    width: 90%;
}



.berry-div {
    min-width: 100px;
   
}

.headline-box h1 {
    font-size: 1.5rem;
}

.berry-details h2 {
    font-size: 1.25rem;
}

.berry-details p {
    font-size: 1rem;
}

.container {
  display: flex;
  flex-direction: column;
}

.berry-details {
  order: 1;
}

.images-div {
  order: 2;
}
}




  




@media screen and (max-width: 800px) {


#lgtimeline {
  display: none;
}

#visitbg1 {
  height: auto;
  background-position: top;
  background-image: linear-gradient(30deg, rgb(203,16,80, 0.8), rgba(255, 189, 20, 0.8)), url("./images/visitfarm.jpg");
}

.carousel {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  justify-items: center;
  align-items: start;
}


.carousel-container {
  width: 100%;
}



.headline-box h1 {
  font-size: 2rem;
}



.berry-details h2 {
  font-size: 1.5rem;
}

.berry-details p {
  font-size: 1.25rem;
}
.details-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  justify-items: center;
  align-items: end;
}
.berry-details {
  width: 90%; 
  margin: 0 auto; 
  
}

}


@media screen and (min-width: 801px) {

#mobiletimeline {
  display: none;
}
#visitbg1 {
  height: auto;
  background-image: linear-gradient(30deg, rgb(203,16,80, 0.8), rgba(255, 189, 20, 0.8)), url("./images/visitfarm.jpg");
}

.carousel-container {
  width: 30%;
  
}




.berry-div {
  max-width: 250px;
}

.headline-box h1 {
  font-size: 2.5rem;
}

.berry-details h2 {
  font-size: 1.75rem;
}

.berry-details p {
  font-size: 1.5rem;
}

.container {
  display: flex;
  flex-direction: column;
}

.berry-details {
  order: 2;
}

.images-div {
  order: 1;
}

}