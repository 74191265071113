@tailwind base;
@tailwind components;
@tailwind utilities;



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'friz';
  font-style: normal;
  font-weight: normal;
  src: local('Friz Quadrata Regular OS'), url('friz-quadrata-regular-os-5870333951e7c.woff') format('woff');
  }
  

  @font-face {
  font-family: 'friz';
  font-style: normal;
  font-weight: normal;
  src: local('Friz Quadrata Bold Italic OS'), url('friz-quadrata-bold-italic-os-5870341205e0f.woff') format('woff');
  }